* {
    text-decoration: none;
    font-family: Roboto, sans-serif;
    scrollbar-width: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

#placeholder {
    width: 100vw;
    height: 100dvh;
    background-color: #1f1715;
    position: fixed;
    display: flex;
    vertical-align: center;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: white;
    z-index: 9999;
    visibility: visible;
}

.placeholder {
    animation-name: hide;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
}

@keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.main-img {
    background: url('../../public/header1.jpg') no-repeat center;
    background-size: cover;
    height: 100dvh;
    box-shadow: inset 26vw 0 20vw 3vw rgba(0, 0, 0, 0.75);
}

.modal-focus:focus-visible {
    outline: none;
}

.main-content-container {
    height: 100dvh;
    width: 100%;
    box-shadow: inset -10vw 0 20vw 3vw rgba(0, 0, 0, 0.75);
}

.page-container {
    width: 100%;
    height: 100dvh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.main-content {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
}

.main-logo {
    right: 0;
    position: fixed;
    top: 40px;
    z-index: 100;
    width: calc(17vw);
    display: flex;
    justify-content: center;
}

.main-logo > div {
    position: relative;
    padding: 50px;
    width: 150px;
    height: 150px;
}

.main-logo span {
    position: absolute;
    font-family: "Great Vibes", cursive;
    color: whitesmoke;
    font-size: 40pt;
    padding: 20px;
}

.main-logo span:nth-child(1) {
    top: 0;
    left: 0;
    text-shadow: 3px 2px 3px #9c7456;
}

.main-logo span:nth-child(2) {
    top: 36px;
    left: 40px;
    text-shadow: 3px 2px 3px #9c7456;
    font-size: 45pt;
}

.main-name {
    font-family: Lobster, Roboto, sans-serif;
    color: white;
    font-size: 60px;
    padding: 0 5px;
    font-style: italic;
    letter-spacing: 5px;
    word-spacing: 20px;
    width: auto;
}

.main-define {
    margin: 30px auto calc(16vh - 70px) auto;
    color: white;
    font-family: "Yeseva One", serif;
    font-weight: 300;
    font-style: italic;
    font-size: 22px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}

.main-define-line {
    transform: scaleX(30);
}

.main-aside {
    width: max-content;
    padding-left: 7vw;
}

.menu-button {
    padding: 7px;
    color: white;
    letter-spacing: 6px;
    font-size: 20px;
    text-transform: uppercase;
    width: max-content;
    display: block;
    transition: 80ms ease-in all;
    overflow-y: visible;
    font-weight: bold;
}

.menu-button:hover {
    transform: scale(1.1);
    color: #d18e5c !important;
}

.main-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
    width: calc(20vw);
    align-items: center;
}

.main-menu > div {
    padding: 0;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-menu > div div {
    margin-top: 16px;
}

.menu-social {
    position: fixed;
    top: 56%;
    margin-top: -25vh;
    z-index: 2;
}

.main-social {
    position: fixed;
    bottom: 5%;
    z-index: 2;
    width: calc(22vw);
    display: flex;
    gap: 20px;
}

.main-social > div {
    margin: 0 auto;
}

.main-social a {
    height: 100px;
    width: 100px;
    transform: scale(1.2);
    transition: 80ms ease-in all;
}

.main-social a:hover {
    transform: scale(1.4);
}

.secondary-img {
    position: fixed;
    width: 100%;
    height: 100dvh;
    top: 0;
    left: 0;
    background: url('../../public/secondary11.jpg') no-repeat center;
    background-size: cover;
    z-index: -1;
    box-shadow: inset 21vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
}

.secondary-img div {
    width: 100%;
    height: 100%;
    box-shadow: inset -21vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
}

.page {
    width: 100%;
    height: 100dvh;
    scroll-snap-align: center;
    scroll-behavior: smooth;
    position: relative;
}

.secondary-page {
    width: 60vw;
    min-width: 800px;
    margin: 0 auto;
}

.secondary-page-container {
    color: rgb(230, 230, 230);
    background-color: rgba(15, 15, 15, 0.4);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 3vh 2vh rgba(15, 15, 15, 0.9);
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 0;
    flex-direction: column;
    position: relative;
}

.secondary-page-container > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center-of-page {
    height: 1px !important;
    width: 1px !important;
    position: absolute;
    top: 50%;
    left: 50%;
}

.center-of-page-video {
    position: absolute;
    top: 50%;
}

.center-of-page-photo {
    position: fixed;
    top: 150%;
}

.secondary-page-container h2 {
    font-size: 16px;
}

#media-container {
    gap: 10px;
    justify-content: center;
}

.secondary-page-container .video-container-main {
    width: 100%;
    padding: 16px 0 0 0;
    max-height: 42%;
    height: 42%;
    position: relative;
    border: 1px solid rgba(209, 142, 92, 0.3);
    background-color: rgba(209, 142, 92, 0.1);
    border-radius: 7px;
}

.secondary-page-container .video-container {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    gap: 15px;
    position: relative;
    scrollbar-width: initial;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    min-height: 100%;
}

.video-container::-webkit-scrollbar {
    height: 5px;
    background-color: rgba(150, 150, 150, 0.35);
    border-radius: 10px;
}

.video-container::-webkit-scrollbar-thumb {
    background-color: rgb(209, 142, 92);
    border-radius: 10px;
}

.video-container .video-wrapper {
    height: 100%;
    white-space: nowrap;
    justify-content: space-between;
    font-size: 16px;
    max-height: 100%;
}

/*.video-wrapper p {*/
/*    min-height: auto;*/
/*    line-height: 20px;*/
/*}*/

.video-container .video {
    max-height: calc(100% - 40px);
    height: calc(100% - 40px);
}

.video-container .scroll-align {
    scroll-snap-align: center;
}

.video-scroll-button {
    width: 60px;
    height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    transform: scale(2);
    z-index: 100;
    cursor: pointer;
    transition: 80ms ease-in;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.video-link {
    text-decoration: none;
    color: white;
    min-height: 30px;
}

.video-link:hover {
    color: rgb(209, 142, 92);
}

.left {
    left: 40px;
}

.left:hover {
    transform: translateX(10px) scale(2.5, 2.9);
    color: rgb(209, 142, 92);
}

.right {
    right: 40px;
    transform: rotate(180deg) scale(2);
}

.right:hover {
    transform: rotate(180deg) translateX(10px) scale(2.5, 2.9);
    color: rgb(209, 142, 92);
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }
    3% {
        opacity: 1;
        max-height: 500px;
        padding: 20px;
        font-size: 15px;
        border: 2px solid;
        margin-bottom: 20px;
        transform: translateX(0);
    }
    97% {
        opacity: 1;
        max-height: 1000px;
        padding: 20px;
        font-size: 15px;
        border: 2px solid;
        margin-bottom: 20px;
        transform: translateX(0);
    }
    100% {
        max-height: 0;
        padding: 0 20px;
        font-size: 0;
        border: none;
        opacity: 1;
        margin-bottom: 0;
        display: none;
        transform: translateX(-500px);
    }
}

.modal-message-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    height: max-content;
}

.modal-message {
    background-color: rgba(255, 255, 255, 1);
    padding: 0 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: inset 0 0 8px 3px green;
    border: none;
    color: green;
    animation-name: appear;
    animation-duration: 9850ms;
    animation-fill-mode: forwards;
    transform-origin: bottom;
    font-weight: 500;
    max-height: 0;
    font-size: 0;
    margin-bottom: 0;
}

.modal-message-error {
    color: red;
    border-color: red;
    box-shadow: inset 0 0 8px 3px red;
}

.modal-left {
    left: 50px;
    transform: scale(2.2);
}

.modal-right {
    right: 50px;
    transform: rotate(180deg) scale(2.2);
}

.modal-right:hover {
    color: white;
    transform: rotate(180deg) scale(2.5);
}

.modal-left:hover {
    color: white;
    transform: scale(2.5);
}

.photo-container {
    display: flex;
    height: 100%;
    white-space: nowrap;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    scroll-snap-align: center;
    cursor: pointer;
    justify-content: space-between;
}

.photo-container p {
    height: 50px;
    white-space: collapse balance;
    min-height: max-content;
}

.photo-container img {
    height: 100%;
}

.photo-container div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.photo-container h4 {
    text-align: center;
    vertical-align: center;
}

.photo {
    gap: 10px !important;
}

.modal-photo::backdrop {
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    height: 100%;
}

.modal-photo-container {
    position: relative;
    width: inherit;
    height: inherit;
    display: block;
}

.modal-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #61dafb;
    cursor: pointer;
}

.modal-sheet {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
}

.modal-img-container img {
    max-height: 90%;
    width: max-content;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.modal-img-container {
    height: 90%;
    width: 100%;
    margin: auto;
    text-align: center;
    vertical-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container-fluid {
    display: none !important;
    transform: scale(0.5);
}

.schedule-top {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: center;
    vertical-align: center;
    margin-bottom: 5px;
    padding: 5px 0;
}

.schedule-top h3 {
    font-size: 18px;
}

.schedule-bottom {
    width: 100%;
    justify-content: center;
    text-align: center;
    min-height: 24px;
    margin-top: 15px;
}

.schedule-bottom button {
    box-sizing: border-box;
    padding: 3px;
    background-color: unset;
    outline: none;
    border: none;
    color: white;
    transition: all 80ms ease-in;
    font-size: 15px;
}

.schedule-bottom button:hover {
    color: rgb(209, 142, 92);
    cursor: pointer;
}

.schedule-item-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 15px;
}

.schedule-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: max-content;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: auto;
    padding-right: 8px;
}

.schedule-wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(150, 150, 150, 0.35);
    border-radius: 10px;
}

.schedule-wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(209, 142, 92);
    border-radius: 10px;
}

.schedule-placeholder-item {
    text-align: center;
}

.schedule-item {
    display: flex;
    border: 1px solid rgba(209, 142, 92, 0.3);
    width: 100%;
    padding: 8px;
    border-radius: 7px;
    background-color: rgba(209, 142, 92, 0.1);
    backdrop-filter: blur(15px);
    align-items: center;
    white-space: initial;
    word-wrap: break-word;
    justify-content: space-between;
    min-height: 134px;
    box-sizing: border-box;
}

.schedule-item-left {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.schedule-item-img {
    position: relative;
    width: 116px;
    height: 116px;
    display: flex;
    border-radius: 75px;
    cursor: pointer;
    vertical-align: center;
}

.schedule-item-img div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.schedule-item-img img {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.schedule-img-wider {
    height: 100%;
    width: unset;
}

.schedule-img-higher {
    height: auto;
    width: 116px;
}

.schedule-item-name {
    font-weight: 400;
    font-size: 17px;
    width: 30%;
    max-height: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.schedule-item-place-time {
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 10px;
}

.schedule-item-place, .schedule-item-time {
    align-items: center;
    display: flex;
    gap: 8px;
}

.schedule-item-place p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.schedule-button-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    color: white;
    padding: 4px;
    min-width: 128px;
}

.schedule-button {
    width: 120px;
    color: white;
    padding: 5px 9px;
    border-radius: 7px;
    border: none;
    outline: none;
    background-color: rgb(209, 142, 92);
    align-items: center;
    display: flex;
    gap: 5px;
    box-sizing: border-box;
    transition: all 80ms ease-in;
    font-weight: 500;
}

.schedule-button-link {
    border-radius: 7px;
    transition: all 80ms ease-in;
}

.schedule-button-link:hover {
    box-shadow: 0 0 5px 2px white;
}

.schedule-button:hover {
    box-shadow: 0 0 5px 2px white;
    cursor: pointer;
}

.schedule-button a {
    color: white;
}

.schedule-content-container {
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.schedule-container {
    height: calc(70% - 20px);
}

.schedule-add {
    height: auto;
}

.schedule-form {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: space-evenly;
}

.schedule-form input {
    height: max-content;
    margin: 0 !important;
}

.schedule-form textarea {
    outline: none;
    border-radius: 7px;
    padding: 10px;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0);
    width: calc(50% - 5px);
    height: 200px;
    resize: none;
    color: white;
}

.schedule-form textarea::placeholder {
    color: white;
}

.schedule-modal {
    width: 1000px;
    height: 600px;
    max-height: 93%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 0 15px 3px rgb(209, 142, 92);
}

.schedule-modal-bottom {
    background-color: rgba(209, 142, 92, 0.75);
    padding: 15px;
    color: white;
    text-indent: 30px;
}

.schedule-modal-blur {
    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.55);
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    text-shadow: black;
    justify-content: space-between;
}

.schedule-modal-top {
    display: flex;
    justify-content: space-between;
}

.schedule-modal-name {
    margin-bottom: 20px;
    font-size: 19px;
    font-weight: 600;
    margin-left: 30px;
}

.schedule-modal-place {
    margin-bottom: 10px;
}

.schedule-modal-time, .schedule-modal-place {
    display: flex;
    align-items: center;
}

.schedule-flex {
    width: 100%;
}

.schedule-flex > div:nth-child(1) {
    width: calc(100% - 105px);
}

.error {
    color: darkred;
}

.success {
    color: darkgreen;
}

.admin-wrapper {
    width: 100%;
    height: 100dvh;
    background: url('../../public/secondary11.jpg') no-repeat center;
    display: flex;
    flex-direction: column;
}

.admin-container {
    width: 1400px;
    height: 100dvh;

    border-radius: 7px;
    backdrop-filter: blur(6px);
    background-color: rgba(30, 30, 30, 0.3);
    color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
}

.admin-content {
    margin-top: 20px;
    min-height: 80vh;
    max-height: 85vh;
}

.admin-h {
    display: flex;
    margin: 0 auto 10px auto;
    border-bottom: 2px solid white;
    width: 100%;
    justify-content: center;
    padding-bottom: 10px;
    align-items: center;
    height: 5vh;
}

.admin-h h3 {
    margin-right: 30px;
    vertical-align: center;
    text-align: center;
    height: max-content;
}

.admin-menu {
    display: flex;
    gap: 10px;
    margin: 0 auto 0 auto;
    height: 3.5vh;
}

.admin-menu-button {
    padding: 10px;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
    transition: all 100ms ease-in;
    cursor: pointer;
    box-sizing: border-box;
}

.admin-menu-button:hover {
    color: rgb(235, 174, 52) !important;
    border-color: rgb(235, 174, 52) !important;
}

input[type="file"] {
    display: none;
}

.admin-button {
    padding: 10px;
    border: none;
    background-color: transparent;
    color: white;
    transition: all 100ms ease-in;
    cursor: pointer;
}

.admin-button-container {
    position: relative;
    width: 100%;
}

.admin-button:hover {
    color: rgb(235, 174, 52) !important;
    border-color: rgb(235, 174, 52) !important;
}

.admin-schedule-edit-container {
    background-color: rgba(235, 174, 52, 0.2);
}

.form-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    transition: all 100ms ease-in;
}

.custom-file-upload:hover {
    color: rgb(235, 174, 52) !important;
    border-color: rgb(235, 174, 52) !important;
}

.admin-photo-container {
    margin-top: 20px;
}

.pictures-container {
    border-radius: 5px;
    border: 1px solid white;
    max-height: 80vh;
    margin-bottom: 20px;
}

.admin-pic-container {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);;
    align-items: center;
    font-size: 14px;
}

.admin-pic-container:hover {
    border-top: 1px solid rgb(235, 174, 52);
    border-bottom: 1px solid rgb(235, 174, 52);
}

.admin-pic {
    width: 200px;
}

.admin-icons {
    width: 200px;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.admin-icons div {
    cursor: pointer;
    transition: all 80ms ease-in;
}

.admin-icons div:hover * {
    color: rgb(235, 174, 52);
}

.admin-pic-container img {
    min-height: 50px;
    max-height: 50px;
}

.pictures-header {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    position: sticky;
    top: 0;
    background-color: rgba(30, 30, 30, 0.9);;
    backdrop-filter: blur(5px);
    height: 36px;
    align-items: center;
    font-size: 14px;
}

.admin-video-add input, .admin-schedule-edit {
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid slategrey;
    transition: all 100ms ease-in;
    margin: 0 10px;
    color: white;
    resize: none;
}

.admin-schedule-edit {
    margin: 3px 3px;
}

textarea.admin-schedule-edit {
    height: 200px;
}

.admin-video-add input::placeholder {
    color: white;
}

.admin-video-add input:hover {
    border-color: rgb(235, 174, 52);
    color: rgb(235, 174, 52);
}

.admin-video-add input:focus {
    color: rgb(54, 191, 90);
    border-color: rgb(54, 191, 90);
}

.admin-video-add input:focus::placeholder {
    color: rgb(54, 191, 90);
}

.admin-video-add input:hover::placeholder {
    color: rgb(235, 174, 52);
}

.bio-container {
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.bio-container > div {
    padding: 0 15px;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: auto;
}

.bio-container > div::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(150, 150, 150, 0.35);
    border-radius: 10px;
}

.bio-container > div::-webkit-scrollbar-thumb {
    background-color: rgb(209, 142, 92);
    border-radius: 10px;
}

.bio-container p {
    text-align: left;
    margin-bottom: 24px;
    text-indent: 30px;
    letter-spacing: normal;
    line-height: 45px;
    font-size: 17px;
}

.bio-container p::first-letter {
    font-weight: normal;
    font-size: 50px;
    color: #d18e5c;
    font-family: "Great Vibes", cursive;
    line-height: initial;
}

.bio-textarea {
    width: 100%;
    min-height: 80vh;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 6px;
}

.bio-textarea:focus {
    outline: none !important;
    border: 1px solid rgb(235, 174, 52);
}

.start-of-bio {
    height: 1px;
    width: 100%;
}

#bio-container {
    min-height: 80dvh;
    max-height: 80dvh;
}

.bio-container img {
    max-height: 600px;
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.bio-container span, .contacts-content span {
    display: block;
    color: rgb(180, 180, 180);
    font-style: italic;
    margin: 10px auto 20px auto;
    font-size: 16px;
}

.admin-login {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.admin-login form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    gap: 20px;
    padding: 10px 0;
    align-items: center;
}

.content-contacts {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#media-container {
    margin: auto;
    padding: 0 15px;
}

.content-contacts span {
    margin-bottom: 16px;
}

.content-contacts > div {
    margin: auto;
}

.content-contacts form {
    display: flex;
    flex-direction: column;
    margin: 20px auto 0 auto;
    gap: 20px;
    max-width: 600px;
    min-width: 500px;
}

.content-contacts form input, .admin-input {
    padding: 10px;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    outline: none;
    transition: all 120ms ease-in;
    color: white;
}

.content-contacts form input::placeholder {
    color: white;
}

.content-contacts form input:focus {
    border-color: rgb(209, 142, 92) !important;
    outline: 1px solid rgb(209, 142, 92) !important;
    color: rgb(209, 142, 92) !important;
}

.content-contacts form input:focus::placeholder {
    color: rgb(209, 142, 92) !important;
}

.content-contacts form textarea {
    outline: none;
    border-radius: 7px;
    padding: 10px;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0);
    transition: all 120ms ease-in;
    height: 200px;
    resize: none;
    color: white;
}

.content-contacts form textarea::placeholder {
    color: white;
}

.content-contacts form textarea:focus {
    border-color: rgb(209, 142, 92) !important;
    outline: 1px solid rgb(209, 142, 92) !important;
    color: rgb(209, 142, 92) !important;
}

.content-contacts form textarea:focus::placeholder {
    color: rgb(209, 142, 92) !important;
}

.content-contacts form button {
    padding: 16px;
    border: 1px solid white;
    width: max-content;
    margin: 0 auto 10px auto;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    cursor: pointer;
    transition: all 120ms ease-in;
}

.content-contacts form button:hover {
    border-color: rgb(209, 142, 92);
    color: rgb(209, 142, 92);
    outline: 1px solid rgb(209, 142, 92);
}

.content-contacts form button:active {
    border-color: green;
    color: green;
    outline: none;
}

.message-error {
    color: red;
    text-align: center;
}

.message-success {
    color: green;
    text-align: center;
    margin: auto;
}

.menu-icon {
    width: 35px;
}

.menu-icon div {
    width: 35px;
    height: 4px;
    background-color: white;
    margin: 6px 0;
    transition: all 120ms ease-in;
}

.menu-icon-container {
    position: fixed;
    top: 10px;
    right: 15px;
    display: flex;
    flex-direction: row-reverse;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(4px);
    z-index: 2;
}

.menu-icon-container > div {
    position: relative;
}

@media screen and (min-device-width: 2561px) {
    .main-content-container {
        width: 2560px;
        margin: 0 auto;
        box-shadow: unset;
    }

    .main-img {
        background: url('../../public/header1.jpg') no-repeat center;
        background-size: cover;
        height: 100dvh;

        /*box-shadow: inset 500px 0 300px 200px rgba(0,0,0,0.75);*/

    }

    .menu-social {
        left: calc(50% - 1300px);
        width: 300px;
    }

    .main-menu {
        width: 300px;
    }

    .main-social {
        width: 300px;
        left: calc(50% - 1300px);
    }

    .main-logo {
        right: calc(50% - 1300px);
        width: 300px;
    }

    .page {
        width: 100%;
        margin: 0 auto;
        height: 100dvh;

        scroll-snap-align: center;
        position: relative;
    }

    .secondary-page {
        padding: 0 5px;
        width: 2000px;
    }

    .secondary-page-container {
        color: rgb(230, 230, 230);
        background-color: rgba(15, 15, 15, 0.4);
        backdrop-filter: blur(5px);
        box-shadow: 0 0 3vh 2vh rgba(15, 15, 15, 0.9);
        width: 100%;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1vw;
        flex-direction: column;
        position: relative;
    }
}

@media screen and (min-device-width: 661px) and (max-device-width: 1200px) {
    .main-logo {
        right: -30px;
        top: unset;
        bottom: -40px;
        width: 120px;
    }

    .main-logo > div {
        padding: 10px;
        width: 120px;
        height: 120px;
    }

    .main-logo span {
        font-size: 18pt;
    }

    .main-logo span:nth-child(2) {
        top: 16px;
        left: 18px;
        font-size: 23pt;
    }

    .main-name {
        font-size: 43px;
        letter-spacing: 3px;
        word-spacing: 10px;
    }

    .main-define {
        margin: 20px auto;
        font-size: 15px;
    }

    .main-define-line {
        transform: scaleX(40);
    }

    .main-aside {
        padding-top: 10px;
        padding-left: 8vw;
    }

    .menu-button {
        letter-spacing: 2px;
        font-size: 13px;
    }

    .main-menu {
        width: 100%;
        min-height: auto;
    }

    .main-menu > div div {
        margin-top: 0;
    }

    .main-menu > div {
        align-items: center;
        vertical-align: center;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 660px;
    }

    .menu-social {
        top: 0;
        left: 0;
        margin: 0 auto;
        background-color: rgba(30, 30, 40, 0.3);
        backdrop-filter: blur(5px);
        width: 100%;
        padding: 0 10px;
    }

    .main-social {
        bottom: 0;
        width: auto;
        flex-direction: column;
        gap: 0;
    }

    .main-social > div {
        margin: 0;
    }

    .main-social a {
        height: 44px !important;
        width: 44px !important;
        transform: scale(0.8);
    }

    .main-social a:hover {
        transform: scale(0.9);
    }

    .schedule-wrapper {
        gap: 8px;
        padding: 0 6px;
    }

    .schedule-wrapper::-webkit-scrollbar {
        width: 6px;
    }

    .schedule-item-container {
        gap: 4px;
        padding: 18px 0;
    }

    .schedule-item-name {
        font-size: 30px;
        -webkit-line-clamp: 5;
    }

    .schedule-item-name {
        font-size: 15px;
        min-width: 40%;
        max-width: 40%;
    }

    .schedule-item-place-time {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        width: auto;
        max-width: 40%;
        gap: 4px;
    }

    .schedule-item {
        min-height: 100px !important;
        max-height: 100px;
        padding: 3px;
    }

    .schedule-item-left {
        gap: 2px;
    }

    .schedule-item-img {
        width: 80px;
        height: 80px;
    }

    .schedule-top {
        margin-bottom: 0;
        min-height: 30px;
    }

    .schedule-top h3 {
        font-size: 15px;
    }

    .schedule-modal-bottom {
        padding: 10px;
        text-indent: 15px;
    }

    .schedule-modal-blur {
        padding: 10px;
    }

    .schedule-bottom {
        margin-top: 10px;
    }

    .schedule-modal-name {
        margin-bottom: 10px;
        margin-left: 10px;
    }

    #media-container {
        padding: 0;
    }

    .video-scroll-button {
        width: 30px;
        height: 50px;
        top: calc(50% - 25px);
    }

    .video-container-main {
        padding: 0 !important;
    }

    .left {
        left: 30px;
    }

    .left:hover {
        transform: translateX(-4px) scale(2.5, 2.9);
    }

    .right {
        right: 30px;
        transform: rotate(180deg) scale(2);
    }

    .right:hover {
        transform: rotate(180deg) translateX(-4px) scale(2.5, 2.9);
    }

    .secondary-img {
        box-shadow: inset 2vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
    }

    .secondary-img div {
        box-shadow: inset -2vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
    }

    .secondary-page {
        width: 100%;
        min-width: unset;
    }

    #dvizh_app_container {
        width: 95%;
    }

    .modal-left {
        color: white;
        left: 50px;
        transform: scale(1.6);
    }

    .modal-right {
        color: white;
        right: 50px;
        transform: rotate(180deg) scale(1.6);
    }

    .modal-right:hover {
        color: white;
        transform: rotate(180deg) scale(1.8);
    }

    .modal-left:hover {
        color: white;
        transform: scale(1.8);
    }

    #modal-desc {
        font-size: 18px;
    }

    .modal-img-container {
        padding: 0 50px;
        width: 95vw;
        display: flex;
        justify-content: center;
    }

    .bio-container {
        min-height: 100%;
        max-height: 100%;
        width: 97%;
        padding: 10px 0 20px 0;
    }

    .bio-container p {
        margin-bottom: 16px;
        line-height: 30px;
        font-size: 15px;
    }

    .bio-container p::first-letter {
        font-size: 40px;
    }

    .bio-container img {
        max-height: 300px;
        width: max-content;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .bio-container span, .contacts-content span {
        font-size: 15px;
        margin: 10px auto 20px auto;
    }

    .bio-container > div::-webkit-scrollbar {
        width: 6px;
    }

    .content-contacts {
        padding: 30px 0;
    }

    .content-contacts span {
        margin-bottom: 24px;
    }

    .content-contacts h3 {
        font-size: 17px;
    }

    .content-contacts form {
        gap: 10px;
        max-width: 400px;
    }

    .content-contacts form input {
        padding: 8px;
    }

    .content-contacts form textarea {
        padding: 8px;
        height: 140px;
    }

    .content-contacts form button {
        padding: 9px;
        font-weight: bold;
    }

    .secondary-page-container .video-container {
        gap: 10px;
        padding: 10px 0;
    }

    .video-container::-webkit-scrollbar {
        height: 6px;
    }

    .video-container span {
        font-size: 15px;
    }

    .video-container .video {
        white-space: nowrap;
    }

    .photo-container img {
        margin: auto;
    }

    .photo-container p {
        font-size: 15px;
    }

    .video-container .video-wrapper {
        white-space: nowrap;
    }
}

@media screen and (min-device-width: 360px) and (max-device-width: 660px) {
    h4 {
        text-align: center;
        position: absolute;
        top: 5px;
        margin-top: 10px;
    }

    .main-img {
        background: url('../../public/header-mobile1.jpg') no-repeat center;
        background-size: cover;
        height: 100dvh;

    }

    .main-logo {
        right: -40px;
        top: unset;
        bottom: -46px;
        width: 120px;
    }

    .main-logo > div {
        padding: 10px;
        width: 120px;
        height: 120px;
    }

    .main-logo span {
        font-size: 18pt;
    }

    .main-logo span:nth-child(2) {
        top: 16px;
        left: 18px;
        font-size: 23pt;
    }

    .main-name {
        font-size: 20pt;
        letter-spacing: 3px;
        word-spacing: 10px;
    }

    .main-define {
        margin: 20px 0;
        font-size: 10pt;
        padding-right: 80px;
    }

    .main-define-line {
        transform: scaleX(40);
    }

    .main-content {
        padding: 0;
    }

    .main-aside {
        padding-top: 12px;
        padding-left: 4vw;
    }

    .menu-button {
        letter-spacing: 2px;
        font-size: 14px;
    }

    .main-menu {
        width: 100%;
        min-height: 280px;
        align-items: center;
    }

    .main-menu > div {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        width: auto;
    }

    .main-social > div {
        margin: 0;
    }

    .menu-social {
        top: 100px;
        right: 20px;
        margin: 0 auto;
        padding: 0 10px;
    }

    .main-social {
        bottom: 0;
        width: 50vw;
        flex-direction: column;
        gap: 0;
    }

    .main-social a {
        height: 36px !important;
        width: 36px !important;
        transform: scale(1);
    }

    .main-social a:hover {
        transform: scale(1);
    }

    .schedule-wrapper {
        gap: 5px;
        padding: 0 1px;
    }

    .schedule-wrapper::-webkit-scrollbar {
        width: 4px;
    }

    .schedule-item-container {
        gap: 6px;
        padding: 20px 0 14px 0;
    }

    .schedule-item {
        min-height: 84px !important;
        max-height: 84px;
        padding: 3px;
    }

    .schedule-item-left {
        gap: 0;
        justify-content: space-between;
    }

    .schedule-item-img {
        width: 66px;
        height: 66px;
    }

    .schedule-item-name {
        font-weight: 400;
        font-size: 10px;
        min-width: 32%;
        max-width: 32%;
    }

    .schedule-modal-name {
        margin: 0 0 6px 0;
        max-height: 90px;
        text-indent: 13px;
        font-size: 16px;
    }

    .schedule-modal-place {
        margin-bottom: 0;
        font-size: 14px;
    }

    .schedule-modal-time {
        font-size: 14px;
    }

    .schedule-item-place-time {
        font-size: 10px;
        display: flex;
        flex-direction: column;
        width: auto;
        max-width: 43%;
        min-width: 43%;
        gap: 0;
    }

    .schedule-item-place-time svg {
        transform: scale(0.7);
    }

    .schedule-item-place, .schedule-item-time {
        align-items: center;
        display: flex;
        gap: 1px;
    }

    .schedule-button-container {
        display: flex;
        gap: 4px;
        flex-direction: column;
        color: white;
        padding: 0;
        min-width: 86px;
    }

    .schedule-top {
        margin-bottom: 0;
        min-height: 28px;
    }

    .schedule-top h3 {
        font-size: 13px;
    }

    .schedule-button {
        width: 100%;
        padding: 2px 1px 2px 0;
        border-radius: 7px;
        gap: 1px;
        font-weight: 400;
        font-size: 11px;
    }

    .schedule-button svg {
        transform: scale(0.7);
    }

    .schedule-button-modal-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 3px;
    }

    .schedule-button-link {
        width: 100%;
    }

    .schedule-button-modal-container button {
        padding: 2px 3px 2px 0;
        width: 100%;
    }

    .schedule-button:hover {
        box-shadow: unset;
        cursor: pointer;
    }

    .schedule-button {
        font-size: 11px;
    }

    .schedule-button a {
        color: white;
    }

    .schedule-bottom {
        margin-top: 0;
    }

    .schedule-bottom button {
        font-size: 12px;
    }

    .schedule-modal-top {
        flex-direction: column;
    }

    .schedule-flex {
        display: flex;
        justify-content: space-between;
        gap: 2px;
    }

    .schedule-modal-bottom {
        padding: 6px;
        text-indent: 10px;
        font-size: 15px;
    }

    .schedule-modal-blur {
        padding: 6px;
    }


    #media-container {
        padding: 0;
    }

    .video-scroll-button {
        width: 20px;
        height: 40px;
        top: calc(50% - 20px);
    }

    .left {
        left: 20px;
    }

    .left:hover {
        transform: translateX(-4px) scale(2.5, 2.9);
    }

    .right {
        right: 20px;
        transform: rotate(180deg) scale(2);
    }

    .right:hover {
        transform: rotate(180deg) translateX(-4px) scale(2.5, 2.9);
    }

    .secondary-img {
        box-shadow: inset 2vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
    }

    .secondary-img div {
        box-shadow: inset -2vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
    }

    .secondary-page {
        width: 100%;
        min-width: unset;
    }

    .video-container-main {
        padding: 0 !important;
    }

    .secondary-page-container .video-container {
        height: max-content;
        gap: 10px;
        padding: 5px 0;
    }

    .video-container::-webkit-scrollbar {
        height: 5px;
    }

    .video-container .video-wrapper {
        gap: 10px !important;
        white-space: nowrap;
    }

    .video-wrapper p {
        font-size: 15px;
    }

    .video-container .video {
        white-space: nowrap;
    }

    .photo-container p {
        font-size: 15px;
    }

    .modal-img-container img {
        height: auto;
        max-height: 80vh;
    }

    .modal-img-container {
        padding: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .modal-left {
        color: white;
        left: 20px;
        transform: scale(1.4);
    }

    .modal-right {
        color: white;
        right: 20px;
        transform: rotate(180deg) scale(1.4);
    }

    .modal-right:hover {
        color: white;
        transform: rotate(180deg) scale(1.6);
    }

    #modal-desc {
        font-size: 15px;
    }

    .modal-left:hover {
        color: white;
        transform: scale(1.6);
    }

    .bio-container {
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        padding: 24px 5px 20px 5px;
    }

    .bio-container > div {
        padding: 0 4px;
    }

    .bio-container > div::-webkit-scrollbar {
        width: 4px;
    }

    .bio-container p {
        margin-bottom: 16px;
        line-height: 30px;
        font-size: 15px;
    }

    .bio-container p::first-letter {
        font-size: 40px;
    }

    .bio-container img {
        max-height: 300px;
        width: max-content;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .bio-container span, .contacts-content span {
        font-size: 15px;
        margin: 8px auto 16px auto;
    }

    #contacts-container {
        padding-left: 7px;
        padding-right: 7px;
    }

    .content-contacts {
        padding: 20px 0;
        width: 100%;
    }

    .content-contacts > div {

    }

    .content-contacts span {
        margin-bottom: 16px;
    }

    .content-contacts h3 {
        font-size: 17px;
    }

    .content-contacts form {
        gap: 6px;
        max-width: 92vw;
        min-width: 92vw;
    }

    .content-contacts form input {
        padding: 6px;
    }

    .content-contacts form textarea {
        padding: 6px;
        height: 120px;
    }

    .content-contacts form button {
        margin-top: 10px;
        padding: 6px;
        font-weight: bold;
    }

    .content-contacts > div > div:nth-child(3) {
        margin: 20px auto;
    }

    .content-contacts > div > div:nth-child(2) {
        margin-top: 20px;
    }

    .modal-menu {
        position: absolute;
        top: 0;
        width: 100vw !important;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.5);
    }

    .admin-container {
        width: 100vw;
        height: 100dvh;
        padding: 5px;
    }

    .admin-content {
        margin-top: 7px;
    }

    .admin-menu {
        gap: 0;
        height: auto;
    }

    .admin-menu .admin-flags {
        width: 25px !important;
        flex-direction: column !important;
        gap: 5px;
    }

    .admin-menu .admin-flags img {
        height: 10px !important;
    }

    .admin-menu-button {
        height: auto;
        padding: 4px;
    }

    .admin-button {
        padding: 5px;
    }

    .admin-content-container .pictures-container {
        max-height: 70vh;
    }

    .admin-pictures-wrapper .pictures-header > span:nth-child(2) {
        display: none;
    }

    .admin-pic-name {
        display: none !important;
    }

    .admin-pictures-wrapper .admin-pic-container {
        gap: 2px;
    }

    .admin-pictures-wrapper .admin-pic-container input {
        width: 110px;
        padding: 3px;
    }

    .admin-pictures-wrapper .admin-pic-container .admin-pic {
        width: 50px;
    }

    .admin-pictures-wrapper .admin-pic-container img {
        max-height: 50px;
        min-height: unset;
        max-width: 50px;
        height: auto !important;
    }

    .admin-schedule-edit-container {
        flex-direction: column;
        height: max-content;
    }

    .modal-message-container {
        top: 0;
        left: 0;
    }

    .admin-pictures-wrapper .admin-pic-container .admin-icons {
        width: max-content;
        gap: 0;
    }

    .admin-container .pictures-container {
        border-radius: 5px;
        border: 1px solid white;
        max-height: 65vh;
        margin-bottom: 20px;
    }

    .schedule-container .admin-pic-container > div:nth-child(1),
    .schedule-container .pictures-header > span:nth-child(1) {
        max-width: 70px;
    }

    .schedule-container .admin-pic-container > div:nth-child(8),
    .schedule-container .pictures-header > span:nth-child(8) {
        max-width: 70px;
    }

    .schedule-container .admin-pic-container > div:nth-child(3),
    .schedule-container .admin-pic-container > div:nth-child(4),
    .schedule-container .admin-pic-container > div:nth-child(5),
    .schedule-container .admin-pic-container > div:nth-child(6),
    .schedule-container .admin-pic-container > div:nth-child(7),
    .schedule-container .pictures-header > span:nth-child(3),
    .schedule-container .pictures-header > span:nth-child(4),
    .schedule-container .pictures-header > span:nth-child(5),
    .schedule-container .pictures-header > span:nth-child(6),
    .schedule-container .pictures-header > span:nth-child(7) {
        display: none;
    }

    .schedule-add .schedule-form {
        justify-content: space-evenly;
        gap: 3px;
    }

    .admin-video-add form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 3px;
    }

    .admin-video-add form input {
        width: calc(50% - 3px);
        margin: 0;
    }

    .admin-content .bio-container {
        padding-top: 0;
    }

    .admin-video-wrapper .admin-pic-container > div:nth-child(4){
        max-width: max-content;
    }

    .admin-content .bio-textarea {
        min-height: 70dvh;
        max-height: 65dvh;
    }

    .admin-contacts-wrapper #contacts-container h3 {
        font-size: 16px;
    }

    .admin-contacts-wrapper #contacts-container span {
        margin: 0 auto 5px auto;
        font-size: 14px;
    }

    .admin-contacts-wrapper .bio-container {
        padding-bottom: 0;
    }

    .admin-contacts-wrapper .bio-container span {
        margin: 0 auto 5px auto;
    }

    .admin-contacts-wrapper .bio-container a {
        width: 35px !important;
        height: 35px !important;
    }

    .admin-contacts-wrapper > .admin-button {
        margin-bottom: 0 !important;
    }

    .admin-contacts-wrapper #contacts-container {
        font-size: 14px;
    }

    .admin-content .pictures-container span, .admin-content .pictures-container div, .admin-content .pictures-container input {
        font-size: 13px;
    }

    .admin-contacts-wrapper .admin-pic-container a {
        width: 35px !important;
        height: 35px !important;
    }

    .admin-contacts-wrapper .pictures-container {
        max-height: 30dvh;
    }

    .admin-contacts-wrapper .pictures-header > span:nth-child(4),
    .admin-contacts-wrapper .pictures-header > span:nth-child(1)
    {
        max-width: max-content;
    }

    .admin-contacts-wrapper .admin-pic-container > div:nth-child(4),
    .admin-contacts-wrapper .admin-pic-container > div:nth-child(1)
    {
        max-width: max-content;
    }

    .schedule-add .schedule-form > * {
        max-width: 50%;
        width: calc(50% - 3px);
    }

    .admin-pic-container {
        display: flex;
        justify-content: space-around;
        padding: 5px;
        box-sizing: border-box;
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-bottom: 1px solid rgba(0, 0, 0, 0);;
        align-items: center;
        font-size: 14px;
    }

    .admin-pic-container:hover {
        border-top: 1px solid rgb(235, 174, 52);
        border-bottom: 1px solid rgb(235, 174, 52);
    }

    .admin-pic {
        width: 200px;
    }

    .admin-icons {
        width: 200px;
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .admin-icons div {
        cursor: pointer;
        transition: all 80ms ease-in;
    }

    .admin-icons div:hover * {
        color: rgb(235, 174, 52);
    }

    .admin-pic-container img {
        min-height: 50px;
        max-height: 50px;
    }

    .pictures-header {
        display: flex;
        justify-content: space-around;
        padding: 5px;
        position: sticky;
        top: 0;
        background-color: rgba(30, 30, 30, 0.9);;
        backdrop-filter: blur(5px);
        height: 36px;
        align-items: center;
        font-size: 14px;
    }

    .admin-video-add input {
        padding: 5px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid slategrey;
        transition: all 100ms ease-in;
        margin: 0 10px;
        color: white;
    }

    .admin-video-add input::placeholder {
        color: white;
    }

    .admin-video-add input:hover {
        border-color: rgb(235, 174, 52);
        color: rgb(235, 174, 52);
    }

    .admin-video-add input:focus {
        color: rgb(54, 191, 90);
        border-color: rgb(54, 191, 90);
    }

    .admin-video-add input:focus::placeholder {
        color: rgb(54, 191, 90);
    }

    .admin-video-add input:hover::placeholder {
        color: rgb(235, 174, 52);
    }
}

@media screen and (max-device-width: 359px) {
    h4 {
        text-align: center;
        position: absolute;
        top: 5px;
        margin-top: 10px;
    }

    .main-img {
        background: url('../../public/header-mobile1.jpg') no-repeat center;
        background-size: cover;
        height: 100dvh;

    }

    .main-logo {
        right: -40px;
        top: unset;
        bottom: -46px;
        width: 120px;
    }

    .main-logo > div {
        padding: 10px;
        width: 120px;
        height: 120px;
    }

    .main-logo span {
        font-size: 18pt;
    }

    .main-logo span:nth-child(2) {
        top: 16px;
        left: 18px;
        font-size: 23pt;
    }

    .main-name {
        font-size: 25px;
        letter-spacing: 2px;
    }

    .main-define {
        margin: 20px 0;
        font-size: 14px;
        padding-right: 80px;
    }

    .main-define-line {
        transform: scaleX(40);
    }

    .main-content {
        padding: 0;
    }

    .main-aside {
        padding-top: 12px;
        padding-left: 4vw;
    }

    .menu-button {
        letter-spacing: 2px;
        font-size: 15px;
    }

    .main-menu {
        width: 100%;
        min-height: 280px;
        align-items: center;
    }

    .main-menu > div {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        width: auto;
    }

    .menu-social {
        top: 100px;
        right: 20px;
        margin: 0 auto;
        padding: 0 10px;
    }

    .main-social {
        bottom: 0;
        width: max-content;
        max-width: 75vw;
    }

    .main-social a {
        height: 36px !important;
        width: 36px !important;
        transform: scale(1);
    }

    .main-social a:hover {
        transform: scale(1);
    }

    .schedule-top {
        margin-bottom: 10px;
        min-height: 28px;
    }

    .schedule-top h3 {
        font-size: 13px;
    }

    .schedule-wrapper {
        gap: 4px;
        padding: 1px;
    }

    .schedule-wrapper::-webkit-scrollbar {
        width: 4px;
    }

    .schedule-item-container {
        gap: 6px;
        padding: 20px 0 0 0;
    }

    .schedule-item {
        padding: 3px;
    }

    .schedule-item-left {
        gap: 2px;
    }

    .schedule-item-img {
        width: 40px;
        height: 40px;
    }

    .schedule-item-name {
        font-weight: 400;
        font-size: 14px;
        min-width: 40%;
        max-width: 40%;
    }

    .schedule-item-place-time {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        width: auto;
        max-width: 40%;
        gap: 4px;
    }

    .schedule-item-place-time svg {
        transform: scale(0.7);
    }


    .schedule-item-place, .schedule-item-time {
        align-items: center;
        display: flex;
        gap: 1px;
    }

    .schedule-button-container {
        display: flex;
        gap: 4px;
        flex-direction: column;
        color: white;
        padding: 0;
        min-width: 70px;
    }

    .schedule-button {
        width: 70px;
        padding: 2px 4px;
        font-weight: 400;
        justify-content: center;
        font-size: 14px;
    }

    .schedule-button svg {
        display: none;
    }

    .schedule-button:hover {
        box-shadow: unset;
        cursor: pointer;
    }

    .schedule-button a {
        color: white;
    }

    .schedule-modal-bottom {
        padding: 4px;
        text-indent: 7px;
    }

    .schedule-modal-blur {
        padding: 5px;
    }

    .schedule-modal-name {
        margin-left: 0;
        margin-bottom: 5px;
    }

    .schedule-modal-top {
        flex-direction: column;
    }

    .schedule-flex {
        display: flex;
    }

    .schedule-flex > div:nth-child(1) {
        width: calc(100% - 80px) !important;
    }

    .schedule-button-modal-container {
        width: max-content;
        display: flex;
        flex-direction: column-reverse;
        gap: 2px;
    }

    .schedule-button-link {
        width: 80px;
        display: block;
    }

    .schedule-bottom {
        margin-top: 0;
    }

    .video-scroll-button {
        width: 20px;
        height: 40px;
        top: calc(50% - 20px);
    }

    .left {
        left: 20px;
    }

    .left:hover {
        transform: translateX(-4px) scale(2.5, 2.9);
    }

    .right {
        right: 20px;
        transform: rotate(180deg) scale(2);
    }

    .right:hover {
        transform: rotate(180deg) translateX(-4px) scale(2.5, 2.9);
    }

    .secondary-img {
        box-shadow: inset 2vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
    }

    .secondary-img div {
        box-shadow: inset -2vw 0 7vw 3vw rgba(0, 0, 0, 0.85);
    }

    .secondary-page {
        width: 100%;
        min-width: unset;
    }

    #dvizh_app_container {
        width: 95%;
    }

    .secondary-page-container .video-container {
        height: 240px;
        gap: 10px;
        padding: 5px 0;
        scroll-snap-type: unset;
    }

    .video-container-main {
        padding: 0 !important;
    }

    .secondary-page-container .video-container {
        gap: 10px;
        padding: 5px 0;
    }

    .video-container::-webkit-scrollbar {
        height: 4px;
    }

    .video-container span {
        font-size: 15px;
    }

    .video-container .video-wrapper {
        gap: 10px !important;
        white-space: nowrap;
    }

    .video-container .video {
        white-space: nowrap;
    }

    #media-container {
        padding: 0;
    }

    #media-container > div {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .photo-container span {
        font-size: 15px;
    }

    .modal-img-container img {
        height: auto;
        max-height: 80vh;
    }

    .modal-img-container {
        padding: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .modal-left {
        color: white;
        left: 20px;
        transform: scale(1.4);
    }

    .modal-right {
        color: white;
        right: 20px;
        transform: rotate(180deg) scale(1.4);
    }

    .modal-right:hover {
        color: white;
        transform: rotate(180deg) scale(1.6);
    }

    #modal-desc {
        font-size: 15px;
    }

    .modal-left:hover {
        color: white;
        transform: scale(1.6);
    }

    .bio-container {
        min-height: 100%;
        max-height: 100%;
        width: 97%;
        padding: 40px 5px 32px 5px;
    }

    .bio-container p {
        margin-bottom: 16px;
        line-height: 30px;
        font-size: 15px;
    }

    .bio-container p::first-letter {
        font-size: 26px;
    }

    .bio-container img {
        max-height: 300px;
        width: max-content;
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .bio-container span, .contacts-content span {
        font-size: 15px;
        margin: 8px auto 16px auto;
    }

    .bio-container > div::-webkit-scrollbar {
        width: 4px;
    }

    #contacts-container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content-contacts {
        padding: 30px 0;
    }

    .content-contacts span {
        margin-bottom: 16px;
    }

    .content-contacts h3 {
        font-size: 15px;
    }

    .content-contacts form {
        gap: 6px;
        max-width: 85vw;
        min-width: 85vw;
    }

    .content-contacts form input {
        padding: 6px;
    }

    .content-contacts form textarea {
        padding: 6px;
        height: 120px;
    }

    .content-contacts form button {
        margin-top: 10px;
        padding: 6px;
        font-weight: bold;
    }

    .modal-menu {
        position: absolute;
        top: 0;
        width: 100vw !important;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.5);
    }
}